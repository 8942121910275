<template>
  <page-section
    v-loading="form.isLoading"
    :page-title="$t('pages.coverage.clipping_assign.sidebar_title')"
    :collapsable="false"
  >
    <template #tooltip-content>
      <p class="m-b-s">
        {{ $t('pages.coverage.clipping_assign.sidebar_description_1') }}
      </p>
      <p class="m-b-s">
        {{ $t('pages.coverage.clipping_assign.sidebar_description_2') }}
      </p>
    </template>
    <div v-if="preparedClipping" class="Add-clipping__assign">
      <div class="columns">
        <div class="column is-6" style="position:relative">
          <div v-if="fallbackImage" class="image">
            <img :src="fallbackImage" :alt="$t('general.screenshot')">
          </div>
          <div class="hypefactors-value is-width-4/5 is-width-1/2-desktop">
            <h4>{{ $t('general.hypefactors_value') }}</h4>
            <p>
              <template v-if="hfValue">
                {{ formatMoney(hfValue) }}
              </template>
              <template v-else>
                {{ $t('general.not_available') }}
              </template>
            </p>
            <small>{{ $t('pages.coverage.clipping_assign.clipping_saved') }}</small>
          </div>
        </div>
        <div class="column is-6">
          <div class="Card__header has-background">
            <h4>{{ headline }}</h4>
            <small>{{ $t('general.published_at') }}: <b>{{ publicationDate(publishedAt) }}</b></small>
          </div>

          <form @submit.prevent="submit">
            <form-field :label="$t('pages.coverage.clipping_assign.associate_media_relation')">
              <el-select
                v-model="form.relation_ids"
                v-loading="!isLoaded.relations"
                :placeholder="$t('pages.coverage.clipping_assign.select_media_relation')"
                class="associateMediaRelation"
                popper-class="associateMediaRelationModal"
                multiple
              >
                <el-option
                  v-for="relation in relations"
                  :key="relation.id"
                  :label="relation.preferred_name"
                  :value="relation.id"
                />
              </el-select>
            </form-field>

            <form-field :label="$t('pages.coverage.clipping_assign.associate_story')">
              <el-select
                v-model="form.story_ids"
                v-loading="!isLoaded.stories"
                :placeholder="$t('pages.coverage.clipping_assign.select_story')"
                class="associateStoryRelation"
                popper-class="associateStoryModal"
                multiple
              >
                <el-option
                  v-for="story in stories"
                  :key="story.id"
                  :label="truncate(story.headline, 80)"
                  :value="story.id"
                />
              </el-select>
            </form-field>

            <form-field :label="$t('pages.coverage.clipping_assign.associate_campaign')">
              <el-select
                v-model="form.kpi_campaign_ids"
                v-loading="!isLoaded.campaigns"
                :placeholder="$t('pages.coverage.clipping_assign.select_campaign')"
                class="associateKPIRelation"
                popper-class="associateKPIModal"
                multiple
              >
                <el-option
                  v-for="campaign in campaigns"
                  :key="campaign.id"
                  :label="campaign.name"
                  :value="campaign.id"
                />
              </el-select>
            </form-field>

            <form-field :label="$t('general.tags')">
              <clipping-tags-picker
                v-model="form.tags"
                :brand-id="activeBrandId"
                class="associateTagsRelation"
                popper-class="associateTagsModal"
              />
            </form-field>

            <button data-testid="saveButton" type="submit" class="button is-primary pull-right">
              {{ $t('forms.save') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'
import orderBy from 'lodash/orderBy'
import _merge from 'lodash/merge'

import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

import ClippingTagsPicker from '@/components/clippings/ClippingTagsPicker'
import Form from '@/services/forms/Form'

const form = () => new Form({
  relation_ids: { value: [] },
  story_ids: { value: [] },
  kpi_campaign_ids: { value: [] },
  tags: { value: [] },
  media_outlet_id: { value: null }
})

export default {
  components: { ClippingTagsPicker },
  mixins: [TruncateMixin, FormatMoneyMixin, PublicationDateMixin],
  data: () => ({
    form: form(),
    isLoaded: {
      relations: false,
      stories: false,
      campaigns: false
    },
    relations: [],
    stories: [],
    campaigns: [],
    relationsCancelToken: null,
    storiesCancelToken: null,
    campaignsCancelToken: null
  }),

  computed: {
    ...mapGetters('clippings/add', ['headline', 'thumbnail', 'publishedAt', 'preparedClipping', 'redirect', 'suggestedMediaOutletId', 'files']),

    hfValue () {
      return this.preparedClipping.hf_value
    },

    firstFileUrl () {
      return this.$safeGet(this.files, '0.url', '')
    },

    fallbackImage () {
      return this.thumbnail || this.firstFileUrl
    }
  },

  beforeDestroy () {
    this.relationsCancelToken && this.relationsCancelToken.cancel()
    this.storiesCancelToken && this.storiesCancelToken.cancel()
    this.campaignsCancelToken && this.campaignsCancelToken.cancel()
  },
  validations () {
    return {
      form: this.form.rules()
    }
  },

  created () {
    if (!this.preparedClipping) {
      this.$notify.error({
        title: this.$t('errors.error'),
        duration: 5000,
        message: this.$t('errors.cannot_access_clipping_assign_directly')
      })
      return this.$router.push({ name: 'performance.coverage.clipping.edit' })
    }

    this.form.media_outlet_id = this.suggestedMediaOutletId

    Promise.all([
      this.fetchRelations(),
      this.fetchStories(),
      this.fetchCampaigns()
    ]).then(() => {
      if (this.preparedClipping) {
        this.form.relation_ids = this.preparedClipping.relations ? this.preparedClipping.relations.data.map(r => r.id) : []
        this.form.story_ids = this.preparedClipping.stories ? this.preparedClipping.stories.data.map(s => s.id) : []
        this.form.kpi_campaign_ids = this.preparedClipping.kpi_campaigns ? this.preparedClipping.kpi_campaigns.data.map(c => c.id) : []
        this.form.tags = this.preparedClipping.tags ? this.preparedClipping.tags.data.map(t => t.title) : []
      }
    })
  },

  methods: {
    async fetchRelations () {
      try {
        this.relationsCancelToken = this.$api.cancelToken()
        this.relations = await this.$api.fetchAllWithCursor(`/connect/relations?brand=${this.activeBrandId}&sort[]=a_z`, {
          cancelToken: this.relationsCancelToken.token
        })
        // this.relations = orderBy(relations, 'name')
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        console.error(err)
      } finally {
        this.isLoaded.relations = true
      }
    },

    async fetchStories () {
      try {
        this.storiesCancelToken = this.$api.cancelToken()
        const stories = await this.$api.fetchAllWithCursor('stories?brands[]=' + this.activeBrandId, {
          cancelToken: this.storiesCancelToken.token
        })
        this.stories = orderBy(stories, 'headline')
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        console.error(err)
      } finally {
        this.isLoaded.stories = true
      }
    },

    async fetchCampaigns () {
      try {
        this.campaignsCancelToken = this.$api.cancelToken()
        const response = await this.$api.get('kpi-campaigns?brands[]=' + this.activeBrandId, {
          cancelToken: this.campaignsCancelToken.token
        })
        this.campaigns = orderBy(response.data.data, 'name')
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        console.error(err)
      } finally {
        this.isLoaded.campaigns = true
      }
    },

    submit () {
      this.form.setPayloadTransformer(payload => {
        payload.brand = this.activeBrandId

        // TODO: Build the "data" object again, same as on "Edit" step in "ClippingManage".
        // We dont know for now what to pass, so we just use headline
        payload.data = {
          headline: this.headline
        }

        return payload
      })

      this.$store.dispatch('clippings/add/updateClipping', this.form)
        .then(() => {
          const clipingId = this.preparedClipping.id
          const redirectRoute = _merge(
            { name: 'performance.coverage' },
            this.redirect, // saved Vuex redirect path
            {
              query: {
                clipping_preview: clipingId, // make sure to update the clipping preview id
                preserved: true // set preserved, so loadMore can continue where it left off
              }
            })

          this.$router.push(redirectRoute).then(() => {
            this.$bus.$emit('ClippingPreviewModal:open', { id: clipingId })
          })
        })
        .catch(err => {
          this.$displayRequestError(err, this.$t('errors.error'), 5000)
        })
    }
  }
}
</script>
