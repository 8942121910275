<template>
  <el-select
    v-model="computedValue"
    v-loading="isLoading"
    :remote="shouldSearch"
    :remote-method="__search"
    :placeholder="$t('forms.add_tags')"
    v-bind="$attrs"
    multiple
    filterable
    allow-create
    default-first-option
    class="ClippingTagsPicker"
  >
    <el-option
      v-for="tag in itemsToLoopOver"
      :key="tag.id"
      :label="tag.title"
      :value="tag.title"
    />
  </el-select>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import { remoteSelectMixin } from '@hypefactors/shared/js/mixins/remoteSelectMixin'

/**
 * @module ClippingTagsPicker
 */
export default {
  name: 'ClippingTagsPicker',

  extends: remoteSelectMixin,

  props: {
    brandId: {
      type: String,
      required: true
    }
  },

  computed: {
    errorMessage () {
      return this.$t('errors.media_outlets_not_found')
    }
  },

  methods: {
    /**
     * Overridden method to search and order the items by name
     * @param payload
     * @returns {Promise}
     */
    search (payload) {
      payload.params.type = 'clippings'

      return this.$api.get(`/brands/${this.brandId}/tags`, payload)
        .then(response => response.data.data)
        .then(data => {
          return _orderBy(data, 'name')
        })
    }
  }
}
</script>
